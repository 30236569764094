@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(icons.css);

/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Montserrat Regular'),
    local('Montserrat-Regular'),
    url(/fonts/Montserrat-Regular.woff2) format('woff2');
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local('Montserrat Regular'),
    local('Montserrat-Regular'),
    url('/fonts/Montserrat-Medium.woff2') format('woff2');
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('Montserrat Bold'),
    local('Montserrat-Bold'),
    url(/fonts/Montserrat-Bold.woff2) format('woff2');
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src:
    local('Montserrat Black'),
    local('Montserrat-Black'),
    url(/fonts/Montserrat-Black.woff2) format('woff2');
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --hue: 223;
  --bg: hsl(var(--hue), 10%, 90%);
  --fg: hsl(var(--hue), 10%, 10%);
  font-size: 16px;
}

.pl {
  display: block;
  width: 20px;
  height: 20px;
}
.pl__ring,
.pl__ball {
  animation: ring 2s ease-out infinite;
}
.pl__ball {
  animation-name: ball;
}

/* Dark theme  */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 10%, 10%);
    --fg: hsl(var(--hue), 10%, 90%);
  }
}

/* Animation */
@keyframes ring {
  from {
    stroke-dasharray: 0 257 0 0 1 0 0 258;
  }
  25% {
    stroke-dasharray: 0 0 0 0 257 0 258 0;
  }
  50%,
  to {
    stroke-dasharray: 0 0 0 0 0 515 0 0;
  }
}
@keyframes ball {
  from,
  50% {
    animation-timing-function: ease-in;
    stroke-dashoffset: 1;
  }
  64% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -109;
  }
  78% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -145;
  }
  92% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -157;
  }
  57%,
  71%,
  85%,
  99%,
  to {
    animation-timing-function: ease-out;
    stroke-dashoffset: -163;
  }
}

html {
  overflow-x: hidden;
}
body,
html {
  padding: 0;
  margin: 0;
  font-family: Montserrat, sans-serif;
  background-color: #f5f5f5;
}
.checkmark:after,
.ptich:before {
  transform: rotate(45deg);
  position: absolute;
  content: '';
}
.underline-anim,
a,
a:active,
a:focus,
a:hover {
  text-decoration: none;
}

.bg-green:disabled {
  background-color: #8dc63f;
  opacity: 0.6;
}

a {
  color: inherit;
}
.stickyM {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  margin-top: 0 !important;
  z-index: 500;
}

/*.main-font span {*/
/*    font-family: Montserrat!important;*/
/*}*/

.bg-grid {
  background-image: url(/images/decorations/grid.png);
}

.translate-50 {
  transform: translateY(-50%);
}
.translate-50-50 {
  transform: translate(-50%, -50%);
}

.check-heart,
.dropdown-container.hide > .dropdown,
.radio-item input[type='radio'] {
  display: none;
}
#levels .level,
.icon-phone,
.radio-item {
  display: inline-block;
}
.ptich:before {
  left: -15px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #8dc63f;
  border-width: 0 2px 2px 0;
}
@media screen and (min-width: 768px) {
  .columns-2 {
    columns: 2;
  }
  .md\:bg-grid {
    background-image: url(/images/decorations/grid.png);
  }
  .dropdown-container:hover > .dropdown,
  .dropdown:hover {
    display: block;
  }
  .dropdown-container .dropdown-container .dropdown {
    top: 0;
    left: calc(100% + 13px);
    margin-top: 0;
    z-index: 10000;
  }
  .dropdown-container .dropdown-title:hover,
  .dropdown-container .nav-item:hover {
    color: #8dc63f;
    z-index: 1500;
  }
  .dropdown-container .dropdown .dropdown-title:hover,
  .dropdown-container .dropdown .nav-item:hover {
    color: #868686;
    z-index: 1500;
  }
  .dropdown-container .dropdown-container .dropdown:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #670f7a;
    left: -10px;
    top: 17px;
  }
  .dropdown-container .dropdown-container .dropdown:after {
    content: '';
    position: absolute;
    top: 0;
    left: -50px;
    width: 50px;
    height: 100%;
    z-index: 1220;
  }
}

.personage-container {
  z-index: 2;
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000;
  color: #fff;
  padding: 8px;
  z-index: 100;
}
.skip-link:focus {
  top: 0;
}

@keyframes badge {
  0%,
  20% {
    background-color: #09af77;
    color: #fff;
  }
  100%,
  80% {
    color: #09af77;
    background-color: transparent;
  }
}
@keyframes badge-mobile {
  0%,
  20% {
    background-color: #6c37fa;
    color: #fff;
  }
  100%,
  80% {
    color: #6c37fa;
    background-color: transparent;
  }
}
.icon-phone {
  width: 17px;
  height: 15px;
  background-image: url(/images/icons/phone.svg);
  margin-right: 5px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 50%;
}
.flag-tooltip:hover + .for-tooltip,
input:checked ~ .checkmark:after {
  display: block;
}
.checkmark:after {
  display: none;
  left: 5px;
  top: 1px;
  width: 7px;
  height: 13px;
  border: solid #8dc63f;
  border-width: 0 2px 2px 0;
}
.checkmark.purple:after {
  border-right: solid #670f7a;
  border-bottom: solid #670f7a;
}
.checkmark.green:after {
  border-right: solid #fff;
  border-bottom: solid #fff;
}
input:checked ~ .checkmark.green {
  background-color: #8dc63f;
  border-color: #8dc63f;
}
.sticky {
  position: fixed;
  top: 0;
  bottom: auto;
  z-index: 500;
  width: 100%;
  left: 0;
  right: 0;
  padding-top: 3px;
  padding-bottom: 3px;
  transition: 0.3s;
}
#levels .level,
.radio-item,
.rs-slider,
.underline-anim,
.underline-anim svg {
  position: relative;
}
.underline-anim {
  --line: #670f7a;
  --color: #670f7a;
  color: var(--color);
}
.underline-anim span {
  background-image: linear-gradient(0deg, var(--line) 0, var(--line) 100%);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: var(--background-size, 100%) 1px;
  transition: background-size 0.2s linear var(--background-delay, 0.15s);
  transform: translateZ(0);
}

.underline-anim.blog {
  --line: #262628;
  --color: #262628;
  color: var(--color);
}
.underline-anim.blog span {
  background-image: linear-gradient(0deg, var(--line) 0, var(--line) 100%);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: var(--background-size, 100%) 1px;
  transition: background-size 0.2s linear var(--background-delay, 0.15s);
  transform: translateZ(0);
}
.underline-anim svg {
  vertical-align: middle;
  display: inline;
  line-height: 1;
  width: 13px;
  height: 20px;
  left: -2px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1px;
  stroke: var(--line);
  stroke-dasharray: 7.95 30;
  stroke-dashoffset: var(--stroke-dashoffset, 46);
  transition: stroke-dashoffset var(--stroke-duration, 0.15s) var(--stroke-easing, linear) var(--stroke-delay, 0s);
}
.Satisfy {
  font-family: Satisfy;
}
.underline-anim:hover {
  --background-size: 0%;
  --background-delay: 0s;
  --stroke-dashoffset: 26;
  --stroke-duration: 0.3s;
  --stroke-easing: cubic-bezier(0.3, 1.5, 0.5, 1);
  --stroke-delay: 0.195s;
}
.radio-item label {
  display: flex;
  align-items: center;
  color: #262628;
  font-size: 16px;
  cursor: pointer;
}
.radio-item label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #670f7a;
  background-color: transparent;
}
.radio-item-green label:before {
  border: 2px solid #262628;
}
.radio-item-green input[type='radio']:checked + label:before {
  border: 2px solid #8dc63f;
}
.radio-item input[type='radio']:checked + label:after {
  border-radius: 11px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 5px;
  left: 3px;
  content: ' ';
  display: block;
  background: #670f7a;
}
.radio-item-green input[type='radio']:checked + label:after {
  background: #8dc63f;
}
.input-hidden {
  height: 0;
  width: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
  float: right;
}
@keyframes animate-warning {
  0%,
  100%,
  50% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.5;
  }
}
.animate-warning {
  animation: 0.6s ease-in-out forwards animate-warning;
}
.for-heart {
  color: #aab8c2;
  cursor: pointer;
  width: 30px;
  align-self: center;
  transition: color 0.2s ease-in-out;
}
.for-heart:hover {
  color: grey;
}
.for-heart::selection {
  color: none;
  background: 0 0;
}
.for-heart::moz-selection {
  color: none;
  background: 0 0;
}
.grayscale .rs-slider-bar,
.rs-slider-bar {
  background-color: #97979754;
}
.check-heart:checked + label {
  color: #8dc63f;
  will-change: width;
  animation: 1s cubic-bezier(0.17, 0.89, 0.32, 1.49) heart;
}
@keyframes heart {
  0%,
  17.5% {
    width: 0;
  }
}
.rs-slider-bar {
  height: 4px;
  border-radius: 8px;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}
.rs-slider-handle {
  position: absolute;
  top: -80%;
}
[tabindex='-1'] {
  outline: 0;
}
.dropdown-title {
  background: unset;
}

/*.opacity-09 {*/
/*    opacity: .6;*/
/*}*/
.for-tooltip {
  display: none;
  top: 22px;
  left: 20px;
  border: 0.2px solid #fff;
}
.send-btn[disabled],
button.send-btn:disabled {
  background-color: #ccc;
  color: #666;
}
.jello-diagonal-1 {
  -webkit-animation: 0.8s both jello-diagonal-1;
  animation: 0.8s both jello-diagonal-1;
}

@-webkit-keyframes jello-diagonal-1 {
  0%,
  100% {
    -webkit-transform: skew(0deg 0deg);
    transform: skew(0deg 0deg);
  }
  30% {
    -webkit-transform: skew(25deg 25deg);
    transform: skew(25deg 25deg);
  }
  40% {
    -webkit-transform: skew(-15deg, -15deg);
    transform: skew(-15deg, -15deg);
  }
  50% {
    -webkit-transform: skew(15deg, 15deg);
    transform: skew(15deg, 15deg);
  }
  65% {
    -webkit-transform: skew(-5deg, -5deg);
    transform: skew(-5deg, -5deg);
  }
  75% {
    -webkit-transform: skew(5deg, 5deg);
    transform: skew(5deg, 5deg);
  }
}
@keyframes jello-diagonal-1 {
  0%,
  100% {
    -webkit-transform: skew(0deg 0deg);
    transform: skew(0deg 0deg);
  }
  30% {
    -webkit-transform: skew(25deg 25deg);
    transform: skew(25deg 25deg);
  }
  40% {
    -webkit-transform: skew(-15deg, -15deg);
    transform: skew(-15deg, -15deg);
  }
  50% {
    -webkit-transform: skew(15deg, 15deg);
    transform: skew(15deg, 15deg);
  }
  65% {
    -webkit-transform: skew(-5deg, -5deg);
    transform: skew(-5deg, -5deg);
  }
  75% {
    -webkit-transform: skew(5deg, 5deg);
    transform: skew(5deg, 5deg);
  }
}
.slide-in-blurred-left {
  -webkit-animation: 1.5s cubic-bezier(0.23, 1, 0.32, 1) both slide-in-blurred-left;
  animation: 1.5s cubic-bezier(0.23, 1, 0.32, 1) both slide-in-blurred-left;
  height: 0;
}
@-webkit-keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
    height: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
    height: auto;
  }
}
@keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
    height: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
    height: auto;
  }
}

.delay {
  -webkit-animation: 0.9s cubic-bezier(0.23, 1, 0.32, 1) both delay;
  animation: 0.9s cubic-bezier(0.23, 1, 0.32, 1) both delay;
}
@keyframes delay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animated-image {
  width: 291px;

  animation: resize infinite 2s linear;
}

@keyframes resize {
  0% {
    width: 291px;
    opacity: 0.5;
  }
  50% {
    width: 310px;
    opacity: 1;
  }
  100% {
    width: 291px;
    opacity: 0.5;
  }
}
.animated-image2 {
  width: 235px;

  animation: resize infinite 2s linear;
}

@keyframes resize {
  0% {
    width: 235px;
    opacity: 0.5;
  }
  50% {
    width: 252px;
    opacity: 1;
  }
  100% {
    width: 235px;
    opacity: 0.5;
  }
}

/*.flag-tooltip:hover {*/
/*     transform: scale(1.1);*/
/* }*/
/*.grayscale {*/
/*    filter: grayscale(100%);*/
/*}*/
/*.grayscale .rs-slider-handle, .grayscale img, .grayscale svg {*/
/*    filter: blur(1px);*/
/*}*/
/*.jello-horizontal {*/
/*    -webkit-animation: .9s both jello-horizontal;*/
/*    animation: .9s both jello-horizontal;*/
/*}*/
/*@-webkit-keyframes jello-horizontal {*/
/*    0%, 100% {*/
/*        -webkit-transform: scale3d(1, 1, 1);*/
/*        transform: scale3d(1, 1, 1);*/
/*    }*/
/*    30% {*/
/*        -webkit-transform: scale3d(1.25, .75, 1);*/
/*        transform: scale3d(1.25, .75, 1);*/
/*    }*/
/*    40% {*/
/*        -webkit-transform: scale3d(.75, 1.25, 1);*/
/*        transform: scale3d(.75, 1.25, 1);*/
/*    }*/
/*    50% {*/
/*        -webkit-transform: scale3d(1.15, .85, 1);*/
/*        transform: scale3d(1.15, .85, 1);*/
/*    }*/
/*    65% {*/
/*        -webkit-transform: scale3d(.95, 1.05, 1);*/
/*        transform: scale3d(.95, 1.05, 1);*/
/*    }*/
/*    75% {*/
/*        -webkit-transform: scale3d(1.05, .95, 1);*/
/*        transform: scale3d(1.05, .95, 1);*/
/*    }*/
/*}*/
/*@keyframes jello-horizontal {*/
/*     0%, 100% {*/
/*         -webkit-transform: scale3d(1, 1, 1);*/
/*         transform: scale3d(1, 1, 1);*/
/*     }*/
/*     30% {*/
/*         -webkit-transform: scale3d(1.25, .75, 1);*/
/*         transform: scale3d(1.25, .75, 1);*/
/*     }*/
/*     40% {*/
/*         -webkit-transform: scale3d(.75, 1.25, 1);*/
/*         transform: scale3d(.75, 1.25, 1);*/
/*     }*/
/*     50% {*/
/*         -webkit-transform: scale3d(1.15, .85, 1);*/
/*         transform: scale3d(1.15, .85, 1);*/
/*     }*/
/*     65% {*/
/*         -webkit-transform: scale3d(.95, 1.05, 1);*/
/*         transform: scale3d(.95, 1.05, 1);*/
/*     }*/
/*     75% {*/
/*         -webkit-transform: scale3d(1.05, .95, 1);*/
/*         transform: scale3d(1.05, .95, 1);*/
/*     }*/
/* }*/

/*.bg-custom-overlay {*/
/*      background-color: #0000007a;*/
/*  }*/
/*.open svg {*/
/*    transform: rotate(90deg);*/
/*}*/
/*.slide-in-blurred-bottom {*/
/*    -webkit-animation: .6s cubic-bezier(.23, 1, .32, 1) both slide-in-blurred-bottom;*/
/*    animation: .6s cubic-bezier(.23, 1, .32, 1) both slide-in-blurred-bottom;*/
/*}*/
/*@-webkit-keyframes slide-in-blurred-bottom {*/
/*    0% {*/
/*        -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(.2);*/
/*        transform: translateY(1000px) scaleY(2.5) scaleX(.2);*/
/*        -webkit-transform-origin: 50% 100%;*/
/*        transform-origin: 50% 100%;*/
/*        -webkit-filter: blur(40px);*/
/*        filter: blur(40px);*/
/*        opacity: 0;*/
/*    }*/
/*    100% {*/
/*        -webkit-transform: translateY(0) scaleY(1) scaleX(1);*/
/*        transform: translateY(0) scaleY(1) scaleX(1);*/
/*        -webkit-transform-origin: 50% 50%;*/
/*        transform-origin: 50% 50%;*/
/*        -webkit-filter: blur(0);*/
/*        filter: blur(0);*/
/*        opacity: 1;*/
/*    }*/
/*}*/
/*@keyframes slide-in-blurred-bottom {*/
/*     0% {*/
/*         -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(.2);*/
/*         transform: translateY(1000px) scaleY(2.5) scaleX(.2);*/
/*         -webkit-transform-origin: 50% 100%;*/
/*         transform-origin: 50% 100%;*/
/*         -webkit-filter: blur(40px);*/
/*         filter: blur(40px);*/
/*         opacity: 0;*/
/*     }*/
/*     100% {*/
/*         -webkit-transform: translateY(0) scaleY(1) scaleX(1);*/
/*         transform: translateY(0) scaleY(1) scaleX(1);*/
/*         -webkit-transform-origin: 50% 50%;*/
/*         transform-origin: 50% 50%;*/
/*         -webkit-filter: blur(0);*/
/*         filter: blur(0);*/
/*         opacity: 1;*/
/*     }*/
/* }*/

/*.container-lg {*/
/*    max-width: 1200px;*/
/*    width: 100%;*/
/*    margin: 0 auto;*/
/*}*/
/*.voice-recorder {*/
/*    min-height: 100vh;*/
/*    display: grid;*/
/*    place-content: center;*/
/*}*/
/* .recorder-container {*/
/*    min-width: 300px;*/
/*    width: 30vw;*/
/*    height: 70vh;*/
/*    border-radius: 1rem;*/
/*    background: linear-gradient(to right, #41295a, #2f0743);*/
/*    color: #fff;*/
/*}*/
/*.w-6 {*/
/*    width: 20px;*/
/*}*/

input[type='text'],
input[type='number'],
input[type='email'],
button[type='submit'],
textarea {
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .includings > img {
    width: 170px !important;
  }
}

.review-img {
  width: 44px;
  height: 44px;
  object-fit: cover;
}

.bg-gradient-custom {
  background-size: cover;
  background-position: left bottom;
  background: url('https://weprodigi.com/uploads/images/custom-landings/team_back.jpg');
}

.lmtWIHO_gkbTeeyuvoJC.mOUYF5ZmuNL6I7t0mSFg {
  background: transparent !important;
}
.text-16 {
  font-size: 16px;
}

/* styles.css */
.scroll-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.fade-in-from-bottom {
  @apply translate-y-200 opacity-0 ease-out;
}

.animate .fade-in-from-bottom {
  opacity: 0;
  animation-name: fadeInFromBottom;
  animation-duration: 0.7s;

  animation-fill-mode: forwards;
  transform: translateY(100%);
}

.ease-out {
  animation-timing-function: ease-out;
}
.linear {
  animation-timing-function: linear;
}
@keyframes fadeInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(-0%);
  }
}

.blob {
  border-radius: 50%;
  margin-top: 7px;
  height: 25px;
  width: 25px;
  border: 1px solid #f7931a;
  box-shadow: 0 0 0 0 rgba(247, 147, 26, 1);

  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(247, 147, 26, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(247, 147, 26, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(247, 147, 26, 0);
  }
}

.blobGreen {
  border-radius: 50%;
  margin-top: 7px;
  height: 13px;
  width: 13px;
  background: #09af77;
  box-shadow: 0 0 0 0 rgba(9, 175, 119, 1);

  transform: scale(1);
  animation: pulseGreen 2s infinite;
}

@keyframes pulseGreen {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(9, 175, 119, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(247, 147, 26, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(247, 147, 26, 0);
  }
}

.animate .fade-in-from-right {
  opacity: 0;
  animation-name: fadeInFromRight;
  animation-timing-function: ease-out;
  animation-duration: 1s;

  animation-fill-mode: forwards;
}

@keyframes fadeInFromRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }
  to {
    opacity: 1;
  }
}

.animate .fade-in-from-left {
  opacity: 0;

  animation-name: fadeInFromLeft;
  animation-timing-function: ease-out;
  animation-duration: 1s;

  animation-fill-mode: forwards;
}

@keyframes fadeInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-300px);
  }
  to {
    opacity: 1;
  }
}

.shake:hover .horizontal-shake {
  animation: horizontal-shaking 1s infinite;
}

@keyframes horizontal-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(-20deg);
  }
  75% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/*trick line*/

.ticker-line-container {
  overflow: hidden;
}

.ticker-line {
  display: flex;
  width: max-content;
  animation: ticker-animation-left 50s linear infinite;
}

@keyframes ticker-animation-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/*ksa headers*/
@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.word {
  display: inline-block;
  position: relative;
  transform: translateY(100%);
  opacity: 0;
  transition: all 5s ease;
}

.word.animate {
  animation: slideUp 0.9s forwards;
}

.word:nth-child(2).animate {
  animation-delay: 0.5s;
}

.word:nth-child(3).animate {
  animation-delay: 0.8s;
}

.home_card {
  box-shadow: 2px 4px 10px 0px #00000033;
  background: #f3f2f8;
  border-radius: 10px;
  padding: 23px 65px;
  height: 384px;
  width: 100%;
  -webkit-animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    transform: translateY(10%);
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fade-in {
  0% {
    transform: translateY(10%);
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
