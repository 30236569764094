.icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.arrow-left {
  background-image: url(/images/icons/arrow-left.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.icon-info {
  background-image: url(/images/icons/info.svg);
}
.icon-info-yellow {
  background-image: url(/images/icons/info-yellow.svg);
}
.icon-fb {
  background-image: url(/images/icons/social/fb.svg);
}
.icon-fb-green {
  background-image: url(/images/icons/social/fb-green.svg);
}
.icon-insta {
  background-image: url(/images/icons/social/insta.svg);
}
.icon-insta-green {
  background-image: url(/images/icons/social/insta-green.svg);
}
.icon-linkedin {
  background-image: url(/images/icons/social/linkedin.svg);
}
.icon-linkedin-green {
  background-image: url(/images/icons/social/linkedin-green.svg);
}
.icon-youtube {
  background-image: url(/images/icons/social/youtube.svg);
}
.icon-youtube-green {
  background-image: url(/images/icons/social/youtube-green.svg);
}
.icon-x-green {
  background-image: url(/images/icons/social/x-green.svg);
}
.icon-twitter {
  background-image: url(/images/icons/social/x.svg);
}
.icon-twitter-green {
  background-image: url(/images/icons/social/x-green.svg);
}
.icon-email {
  background-image: url(/images/icons/social/email.svg);
}
.icon-calculator {
  display: inline-block;
  width: 22px;
  height: 28px;
  background-image: url(/images/icons/calculator.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  vertical-align: middle;
  margin-right: 7px;
  background-size: contain;
  transition: all 0.4s;
}
.icon-calculator-container:hover .icon-calculator {
  filter: brightness(0) invert(1);
}

.icon-paper {
  display: inline-block;
  width: 22px;
  height: 28px;
  background-image: url(/images/icons/papers.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  vertical-align: middle;
  background-size: contain;
  transition: all 0.3s;
}
.icon-paper2 {
  background-image: url(/images/icons/papers2.svg);
}
.icon-pricing {
  display: inline-block;
  width: 22px;
  height: 28px;
  background-image: url(/images/smm/pricing.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  vertical-align: middle;
  background-size: contain;
  transition: all 0.3s;
}

.icon-paper-container .icon-paper {
  position: absolute;
  transform: translateY(-150%);
  left: 0;
  right: 0;
  margin: 0 auto;
}
.icon-paper-container .icon-pricing {
  position: absolute;
  transform: translateY(-150%);
  left: 0;
  right: 0;
  margin: 0 auto;
}
.icon-paper-container .text {
  transition: all 0.3s;
  transform: translateY(0%);
}
.icon-paper-container:hover .icon-paper {
  transform: translateY(0);
}
.icon-paper-container:hover .icon-pricing {
  transform: translateY(0);
}
.icon-paper-container:hover .text {
  transform: translateY(150%);
}

.icon-arrow-left {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(/images/icons/arrow-left-triangle.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  vertical-align: middle;
  margin-right: 7px;
  background-size: contain;
  transition: all 0.4s;
}
.icon-arrow-left-container:hover .icon-arrow-left {
  filter: brightness(0) invert(1);
}
.navbar-arrow {
  position: absolute;
  right: 0px;
  top: 12px;
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url(/images/icons/navbar-arrow.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s;
}
.show > .navbar-arrow {
  transform: rotate(90deg);
}

.faq-arrow {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url(/images/icons/faq-arrow.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s;
}
.show .faq-arrow {
  transform: rotate(90deg);
}

.includings-arrow {
  width: 10px;
  height: 14px;
  display: inline-block;
  background-image: url(/images/icons/navbar-arrow.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s;
}
.show .includings-arrow {
  transform: rotate(90deg);
}

.icon-social-media-1 {
  width: 38px;
  height: 38px;
  display: inline-block;
  background-image: url(/images/icons/social-media-1.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s;
}
.icon-social-media-2 {
  width: 38px;
  height: 38px;
  display: inline-block;
  background-image: url(/images/icons/social-media-2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s;
}
